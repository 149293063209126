<template>
  <section class="product-section" v-if="!loading">
    <div
      class="product-image"
      v-bind:style="{ backgroundImage: `url('${product.images[0]}')` }"
    ></div>
    <div class="product-description">
      <p class="title">{{ product.name }}</p>
      <p class="description">{{ product.description }}</p>
      <p class="price">{{ selected.unit_amount / 100 }} €</p>
      <div style="color: white" v-if="product.prices.length > 1">
        <p>Tailles:</p>
        <select v-model="selected">
          <option
            v-for="(price, id) in product.prices"
            :key="id"
            :value="price"
            >{{ price.nickname }}</option
          >
        </select>
      </div>
      <div>
        <button @click="addProductToCart" v-if="canOrder">
          Ajouter au panier
        </button>
        <button class="rupture" disabled v-else>RUPTURE DE STOCK</button>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions } from "vuex";
import Vue from "vue";

export default {
  props: ["id"],
  data() {
    return {
      selected: null,
      product: null,
      loading: true,
      showModal: true,
      quantity: 1,
      stock: {
        stocked: false,
        stock: 0,
      },
      metadata: {
        first_name: "",
        last_name: "",
        address: "",
        email: "",
        postal: "",
        city: "",
        phone_number: "",
      },
    };
  },
  metaInfo() {
    return {
      title: `${this.product ? this.product.name : ""} - Wild Fox Band`,
      meta: [
        {
          name: "description",
          content: `Wild Fox Band merchandising: ${
            this.product ? this.product.name : ""
          } - ${this.product ? this.product.description : ""}`,
        },
        {
          property: "og:title",
          content: `${this.product ? this.product.name : ""} - Wild Fox Band`,
        },
        { property: "og:site_name", content: "Wild Fox Band" },
        { property: "og:type", content: "website" },
        { name: "robots", content: "index,follow" },
      ],
    };
  },
  computed: {
    canOrder() {
      if (!this.stock.stocked) return true;
      let canOrdr = this.stock.stock > 0;
      return canOrdr;
    },
  },
  methods: {
    ...mapActions({
      addToCart: "cartModule/addProduct",
    }),
    addProductToCart() {
      this.selected.retrieved_product = this.product;
      this.addToCart(this.selected);
      this.$gtag.event("add_to_cart", { item_id: this.id });
      Vue.notify({
        group: "toast",
        type: "success",
        title: "Produit ajouté au panier",
        closeOnClick: true,
      });
    },
  },
  watch: {
    selected(to) {
      let metadata = to.metadata;
      if (metadata.count != null) {
        let count = parseInt(metadata.count);
        this.stock.stocked = true;
        this.stock.stock = count;
      }
    },
  },
  beforeMount() {
    fetch(this.$API + "/product/" + this.id)
      .then((r) => r.json())
      .then((p) => {
        this.selected = p.prices[0];
        this.product = p;

        let metadata = this.selected.metadata;

        if (metadata.count != null) {
          let count = parseInt(metadata.count);
          this.stock.stocked = true;
          this.stock.stock = count;
        }

        this.$gtag.event("view_item", { item_id: this.id, item_name: this.product.name, price: this.selected.unit_amount / 100});

        this.loading = false;
      });
  },
};
</script>

<style scoped>
.rupture {
  color: red;
}
.delivery * {
  color: white !important;
}

.product-section {
  padding: 100px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 10px;
  justify-items: center;
  align-items: center;
}

.product-image {
  background: 50% 50% no-repeat; /* 50% 50% centers image in div */
  background-size: contain;
  width: 50vw;
  height: 50vh;
}

.description {
  color: aliceblue;
}

@media (min-width: 820px) {
  .product-section {
    grid-template-columns: repeat(2, 1fr);
  }
}

.product-description .title {
  font-size: 1rem;
  color: wheat;
  margin: 5px;
}

.product-description .description {
  margin: 5px;
}

.product-description .price {
  margin: 1rem;
  color: white;
}

@media (min-width: 615px) {
  .product-description .title {
    font-size: 2rem;
  }

  .product-description .price {
    margin: 2rem;
  }
}

button {
  margin: 2rem;
}

input {
  width: 100%;
}
</style>
